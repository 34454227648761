import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  OutboundLink,
  ImageCollection,
  HoursOfOperation,
  Map,
} from "@bluefin/components";
import { Image, Grid, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"home-hero"}>
              <Background.Color color={"white"} textAlign={"center"}>
                <Image
                  size={"huge"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.io/public/honeydipfingerzllc/Final-Final-Logo_Yellow.png?"
                  }
                />
              </Background.Color>
            </Background>
          }
          subfooter={false}
          className={"home-page"}
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14} textAlign={"center"}>
              <Button
                as={OutboundLink}
                to={"https://order.toasttab.com/online/honeydipfingerz"}
                primary={true}
              >
                Order Online
              </Button>
              <Grid
                stackable={true}
                className={"component-section-container custom-banner"}
                verticalAlign={"top"}
                textAlign={"center"}
                columns={"equal"}
              >
                <Grid.Row streched={true}>
                  <Grid.Column>
                    <Image
                      size={"mini"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/honeydipfingerzllc/Honey_Dip_Fingerz__-_Order_Online.png"
                      }
                    />
                    <h4>ORDER YOUR FOOD</h4>
                    <p>
                      Skip the phone by ordering our mouth-watering dishes
                      online, browse through our menu now.
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <Image
                      size={"mini"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/honeydipfingerzllc/Honey_Dip_Fingerz_-_Pickup.png"
                      }
                    />
                    <h4>PICKUP YOUR ORDER</h4>
                    <p>
                      Pick up your order when it is ready, or call for more
                      delivery options.
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <Image
                      size={"mini"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/honeydipfingerzllc/Honey_Dip_Fingerz_-_Reviews.png"
                      }
                    />
                    <h4>WE LOVE YOUR FEEDBACK</h4>
                    <p>
                      Love your food? let us know how we did and how we can
                      serve you better.
                    </p>
                    <Button
                      primary={true}
                      size={"medium"}
                      className={"leave-review-cta"}
                      to={"https://g.page/r/CdPgRUr5IgBuEBM/review"}
                      as={OutboundLink}
                    >
                      Leave a Review
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            verticalAlign={"top"}
            textAlign={"center"}
            centered={true}
          >
            <Grid.Column width={14} textAlign={"center"}>
              <iframe
                width={"500"}
                height={"490"}
                src={
                  "https://player.vimeo.com/video/869586684?h=6ed63bc15b&muted=1&autoplay=1&loop=1&color=0a0a0a"
                }
                frameborder={"0"}
                allow={"autoplay; fullscreen; picture-in-picture"}
                allowfullscreen={true}
                className={"home-video"}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14} textAlign={"center"}>
              <Grid
                stackable={true}
                className={"about-section"}
                verticalAlign={"top"}
                textAlign={"center"}
                columns={2}
              >
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={8}
                  largeScreen={4}
                  textAlign={"left"}
                >
                  <p className={"tagline"}>
                    We are a group of food enthusiasts who produce and deliver
                    tasty and well presented meals. We enjoy going above and
                    beyond to make your orders very special. We are passionate
                    about giving you a unique customer experience whether online
                    or instore and delivering authentic Nigerian food you will
                    love and remember.
                  </p>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={10}
                  largeScreen={12}
                >
                  <ImageCollection
                    images={[
                      "https://fisherman.gumlet.io/public/honeydipfingerzllc/Grand_Opening_1.jpg",
                      "https://fisherman.gumlet.io/public/honeydipfingerzllc/Grand_Opening_2.jpg",
                      "https://fisherman.gumlet.io/public/honeydipfingerzllc/Grand_Opening_3.jpg",
                      "https://fisherman.gumlet.io/public/honeydipfingerzllc/Grand_Opening_4.jpg",
                    ]}
                    as={"gallery"}
                    centered={true}
                    cutUnevenRows={true}
                    smartImageGrouping={false}
                    disable={"none"}
                    header={""}
                    numberOfRowDisplayItems={2}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid.Column width={14} textAlign={"center"}>
            <Grid
              stackable={true}
              className={"component-section-container processing-time"}
              verticalAlign={"top"}
              textAlign={"center"}
              columns={"equal"}
            >
              <Grid.Row stretched={true}>
                <Grid.Column>
                  <Image
                    size={"small"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/honeydipfingerzllc/Untitled_design_2.png"
                    }
                  />
                  <h4>WORKING HOURS</h4>
                  <HoursOfOperation
                    hours={fishermanBusiness.primaryLocation.hours}
                    displayOption={"grouped"}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Map
            address={{
              street: "",
              city: fishermanBusiness.primaryLocation.city,
              state: fishermanBusiness.primaryLocation.state,
              zip: fishermanBusiness.primaryLocation.zipCode,
            }}
          />
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        hours {
          day
          open
          close
          label
        }
        city
        state
        zipCode
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
